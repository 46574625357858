import { z } from "zod";
import { isSchema } from "../utils/isSchema";
import { ElevenLabsModel } from "./ElevenLabsModel";
import { Gender } from "./Gender";

export const Voice = z.object({
  id: z.string(),
  name: z.string(),
  stability: z.number(),
  similarityBoost: z.number(),
  style: z.number(),
  model: ElevenLabsModel,
  sampleFilePath: z.string().optional(),
  gender: Gender,
});

export type Voice = z.infer<typeof Voice>;

export function isVoice(obj: unknown): obj is Voice {
  return isSchema(obj, Voice, "Voice");
}
