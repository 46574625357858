import { z } from "zod";
import { isSchema } from "../utils/isSchema";
import { FaqItem } from "./FaqItem";
import { Timestamp } from "./Timestamp";

export const Faq = z.object({
  id: z.string(),
  name: z.string(),
  talentId: z.string().nullable(),
  items: z.array(FaqItem),
  updatedAt: Timestamp,
  createdAt: Timestamp,
});

export type Faq = z.infer<typeof Faq>;

export function isFaq(obj: unknown): obj is Faq {
  return isSchema(obj, Faq, "Faq");
}
