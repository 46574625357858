import { z } from "zod";
import { isSchema } from "../utils/isSchema";

export const ELEVEN_LABS_MODELS = [
  "eleven_multilingual_v2",
  "eleven_multilingual_v1",
  "eleven_monolingual_v1",
] as const;

export const ElevenLabsModel = z.enum(ELEVEN_LABS_MODELS);

export type ElevenLabsModel = z.infer<typeof ElevenLabsModel>;

export function isElevenLabsModel(obj: unknown): obj is ElevenLabsModel {
  return isSchema(obj, ElevenLabsModel, "ElevenLabsModel");
}
