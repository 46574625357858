import { z } from "zod";
import { getFirebaseFunctionDefinition } from "../utils/getFirebaseFunctionDefinition";

const RequestCreditRewardForSignUpRequest = z.object({});
const RequestCreditRewardForSignUpResponse = z.object({
  creditRewardForSignUp: z.number(),
});

export type RequestCreditRewardForSignUpRequest = z.infer<
  typeof RequestCreditRewardForSignUpRequest
>;

export const RequestCreditRewardForSignUpDefinition =
  getFirebaseFunctionDefinition({
    path: "/users/request-credit-reward-for-sign-up",
    reqType: RequestCreditRewardForSignUpRequest,
    resType: RequestCreditRewardForSignUpResponse,
    isAuthRequired: true,
  });
