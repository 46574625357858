import { z } from "zod";
import { isSchema } from "../utils/isSchema";
import { ChatPromptMessage } from "./ChatPromptMessage";
import { FaqItem } from "./FaqItem";
import { LlmType } from "./LlmType";
import { OpenAiFunctionSchema } from "./OpenAiFunctionSchema";
import { Timestamp } from "./Timestamp";
import { TokenUsage } from "./TokenUsage";

export const ChatPrompt = z.object({
  chatMessageId: z.string(),
  messages: z.array(ChatPromptMessage).optional(),
  functions: z.array(OpenAiFunctionSchema).optional(),
  responseTimeInMs: z.number().optional(),
  responseText: z.string(),
  responseFallbackText: z.string().optional(),
  responseFunction: z
    .object({
      name: z.string(),
      arguments: z.string().optional(),
    })
    .optional(),
  createdAt: Timestamp,
  faqItems: z.array(FaqItem.and(z.object({ score: z.number() }))).optional(),
  faqMinScore: z.number().optional(),
  tokenUsage: TokenUsage.optional(),
  llmType: LlmType.optional(),
  llmTemperature: z.number().optional(),
  llmFineTunedModelId: z.string().optional(),
});

export type ChatPrompt = z.infer<typeof ChatPrompt>;

export function isChatPrompt(obj: unknown): obj is ChatPrompt {
  return isSchema(obj, ChatPrompt, "ChatPrompt");
}
