import { z } from "zod";
import { isSchema } from "../utils/isSchema";
import { AttachmentType } from "./AttachmentType";
import { Dimensions } from "./Dimensions";

export const StaticAttachment = z
  .object({
    id: z.string(),
    originalFileName: z.string(),
    filePath: z.string(),
    context: z.string(),
  })
  .and(
    z.union([
      z.object({
        type: z.literal("IMAGE" satisfies AttachmentType),
        lockedFilePath: z.string(),
        dimensions: Dimensions,
      }),
      z.object({
        type: z.literal("AUDIO" satisfies AttachmentType),
      }),
    ])
  );

export type StaticAttachment = z.infer<typeof StaticAttachment>;

export function isStaticAttachment(obj: unknown): obj is StaticAttachment {
  return isSchema(obj, StaticAttachment, "StaticAttachment");
}
