import { z } from "zod";
import { isSchema } from "../utils/isSchema";

export const GENDERS = ["MALE", "FEMALE", "NON_BINARY"] as const;

export const Gender = z.enum(GENDERS);

export type Gender = z.infer<typeof Gender>;

export function isGender(obj: unknown): obj is Gender {
  return isSchema(obj, Gender, "Gender");
}
