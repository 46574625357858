import { z } from "zod";
import { getFirebaseFunctionDefinition } from "../utils/getFirebaseFunctionDefinition";

const CreateFaqRequest = z.object({
  name: z.string(),
  talentId: z.string().nullable(),
});

const CreateFaqResponse = z.object({
  faqId: z.string(),
});

export type CreateFaqRequest = z.infer<typeof CreateFaqRequest>;

export const CreateFaqDefinition = getFirebaseFunctionDefinition({
  path: "/faqs/create",
  reqType: CreateFaqRequest,
  resType: CreateFaqResponse,
  isAuthRequired: true,
});
