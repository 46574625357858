import { z } from "zod";
import { getFirebaseFunctionDefinition } from "../utils/getFirebaseFunctionDefinition";

const UpdateFaqRequest = z.object({
  id: z.string(),
  name: z.string().optional(),
  items: z.array(
    z.object({
      question: z.string(),
      answer: z.string(),
    })
  ),
});

const UpdateFaqResponse = z.object({
  /**
   * @deprecated TODO: remove as soon as frontend is forced
   */
  faqId: z.string().optional(),
});

export type UpdateFaqRequest = z.infer<typeof UpdateFaqRequest>;

export const UpdateFaqDefinition = getFirebaseFunctionDefinition({
  path: "/faqs/update",
  reqType: UpdateFaqRequest,
  resType: UpdateFaqResponse,
  isAuthRequired: true,
});
