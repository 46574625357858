import { z } from "zod";
import { getFirebaseFunctionDefinition } from "../utils/getFirebaseFunctionDefinition";

const CreateModelRequest = z.object({
  name: z.string(),
  talentId: z.string().nullable(),
});

const CreateModelResponse = z.object({
  modelId: z.string(),
});

export type CreateModelRequest = z.infer<typeof CreateModelRequest>;

export const CreateModelDefinition = getFirebaseFunctionDefinition({
  path: "/models/create",
  reqType: CreateModelRequest,
  resType: CreateModelResponse,
  isAuthRequired: true,
});
