import { z } from "zod";
import { getFirebaseFunctionDefinition } from "../utils/getFirebaseFunctionDefinition";

const CreateCustomTokenRequest = z.object({});

const CreateCustomTokenResponse = z.object({
  customToken: z.string(),
});

export type CreateCustomTokenRequest = z.infer<typeof CreateCustomTokenRequest>;
export type CreateCustomTokenResponse = z.infer<
  typeof CreateCustomTokenResponse
>;

export const CreateCustomTokenDefinition = getFirebaseFunctionDefinition({
  path: "/auth/create-custom-token",
  reqType: CreateCustomTokenRequest,
  resType: CreateCustomTokenResponse,
  isAuthRequired: true,
});
