import { z } from "zod";
import { isSchema } from "../utils/isSchema";
import { SubscriptionStatus } from "./SubscriptionStatus";
import { Timestamp } from "./Timestamp";

export const Subscription = z.object({
  id: z.string(),
  stripeSubscriptionId: z.string(),
  userId: z.string(),
  talentId: z.string(),
  productId: z.string(),
  currentPeriodStartedAt: Timestamp,
  currentPeriodEndedAt: Timestamp,
  status: SubscriptionStatus,
  endedAt: Timestamp.optional(),
  cancelledAt: Timestamp.optional(),
  createdAt: Timestamp,
});

export type Subscription = z.infer<typeof Subscription>;

export function isSubscription(obj: unknown): obj is Subscription {
  return isSchema(obj, Subscription, "Subscription");
}
