import { z } from "zod";
import { isSchema } from "../utils/isSchema";
import { ModelBase } from "./ModelBase";
import { ModelTrainingExample } from "./ModelTrainingExample";
import { ModelVersionStatus } from "./ModelVersionStatus";
import { Timestamp } from "./Timestamp";

export const ModelVersion = z.object({
  id: z.string(),
  status: ModelVersionStatus,
  base: ModelBase,
  openAiJobId: z.string().optional(), // @deprecated use externalJobId instead
  openAiFileId: z.string().optional(), // @deprecated use externalFileId instead
  openAiOrganisationId: z.string().optional(),
  externalFileId: z.string().optional(),
  externalJobId: z.string().optional(),
  fineTunedModelId: z.string().optional(),
  errorMessage: z.string().optional(),
  trainingExamples: z.array(ModelTrainingExample),
  uploadingTrainingFileStartedAt: Timestamp.optional(),
  fineTuningStartedAt: Timestamp.optional(),
  completedAt: Timestamp.optional(),
  createdAt: Timestamp,
});

export type ModelVersion = z.infer<typeof ModelVersion>;

export function isModelVersion(obj: unknown): obj is ModelVersion {
  return isSchema(obj, ModelVersion, "ModelVersion");
}
