import { z } from "zod";
import { IapReceipt } from "../types/IapReceipt";
import { getFirebaseFunctionDefinition } from "../utils/getFirebaseFunctionDefinition";

const ProcessIapReceiptRequest = z.object({
  talentId: z.string(),
  iapReceipt: IapReceipt,
  price: z
    .object({
      currency: z.string(),
      value: z.number(),
    })
    .optional(),
});

const ProcessIapReceiptResponse = z.object({});

export type ProcessIapReceiptRequest = z.infer<typeof ProcessIapReceiptRequest>;

export const ProcessIapReceiptDefinition = getFirebaseFunctionDefinition({
  path: "/iap/receipts/process",
  reqType: ProcessIapReceiptRequest,
  resType: ProcessIapReceiptResponse,
  isAuthRequired: true,
});
