import { z } from "zod";
import { isSchema } from "../utils/isSchema";

export const IAP_PROVIDERS = ["APPLE", "GOOGLE"] as const;

export const IapProvider = z.enum(IAP_PROVIDERS);

export type IapProvider = z.infer<typeof IapProvider>;

export function isIapProvider(obj: unknown): obj is IapProvider {
  return isSchema(obj, IapProvider, "IapProvider");
}
