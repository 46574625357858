import { z } from "zod";
import { FileOwnerType } from "../types/FileOwnerType";
import { getFirebaseFunctionDefinition } from "../utils/getFirebaseFunctionDefinition";

const MAX_IMAGE_SIZE = 5_000_000; // 5MB
const ACCEPTED_IMAGE_TYPES = [
  "image/jpeg",
  "image/jpg",
  "image/png",
  "image/webp",
];

const UploadPublicImageRequest = z.object({
  ownerId: z.string().nonempty(),
  ownerType: FileOwnerType,
  file: z
    .object({
      name: z.string(),
      size: z.number(),
      type: z.string(),
      content: z.string(), // base64 encoded
    })
    .refine((file) => {
      if (ACCEPTED_IMAGE_TYPES.includes(file.type)) {
        return file.size <= MAX_IMAGE_SIZE;
      }
    }, "Invalid format or file size."),
});

const UploadPublicImageResponse = z.object({
  url: z.string(),
});

export type UploadPublicImageRequest = z.infer<typeof UploadPublicImageRequest>;
export type UploadPublicImageResponse = z.infer<
  typeof UploadPublicImageResponse
>;

export const UploadPublicImageDefinition = getFirebaseFunctionDefinition({
  path: "/file/upload-public-image",
  reqType: UploadPublicImageRequest,
  resType: UploadPublicImageResponse,
  isAuthRequired: true,
});
