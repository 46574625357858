import { z } from "zod";
import { getFirebaseFunctionDefinition } from "../utils/getFirebaseFunctionDefinition";

const UnlockChatMessageAttachmentRequest = z.object({
  chatId: z.string(),
  chatMessageId: z.string(),
  attachmentIndex: z.number(),
});

const UnlockChatMessageAttachmentResponse = z.object({
  filePath: z.string(),
  totalUserCredits: z.number(),
});

export type UnlockChatMessageAttachmentRequest = z.infer<
  typeof UnlockChatMessageAttachmentRequest
>;

export const UnlockChatMessageAttachmentDefinition =
  getFirebaseFunctionDefinition({
    path: "/chats/messages/unlock-attachment",
    reqType: UnlockChatMessageAttachmentRequest,
    resType: UnlockChatMessageAttachmentResponse,
    isAuthRequired: true,
  });
