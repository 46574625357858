import { z } from "zod";
import { isSchema } from "../utils/isSchema";

export const CRM_TAG_KEYS = ["last_talent_id"] as const;

export const CrmTagKey = z.enum(CRM_TAG_KEYS);

export type CrmTagKey = z.infer<typeof CrmTagKey>;

export function isCrmTagKey(obj: unknown): obj is CrmTagKey {
  return isSchema(obj, CrmTagKey, "CrmTagKey");
}
