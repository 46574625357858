import { z } from "zod";
import { isSchema } from "../utils/isSchema";

export const Dimensions = z.object({
  width: z.number(),
  height: z.number(),
});

export type Dimensions = z.infer<typeof Dimensions>;

export function isDimensions(obj: unknown): obj is Dimensions {
  return isSchema(obj, Dimensions, "Dimensions");
}
