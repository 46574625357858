import { z } from "zod";
import { getFirebaseFunctionDefinition } from "../utils/getFirebaseFunctionDefinition";

const PrepareBroadcastRequest = z.object({
  broadcastId: z.string(),
});

const PrepareBroadcastResponse = z.object({});

export type PrepareBroadcastRequest = z.infer<typeof PrepareBroadcastRequest>;
export type PrepareBroadcastResponse = z.infer<typeof PrepareBroadcastResponse>;

export const PrepareBroadcastDefinition = getFirebaseFunctionDefinition({
  path: "/broadcasts/prepare",
  reqType: PrepareBroadcastRequest,
  resType: PrepareBroadcastResponse,
  isAuthRequired: true,
});
