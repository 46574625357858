import { z } from "zod";
import { getFirebaseFunctionDefinition } from "../utils/getFirebaseFunctionDefinition";

const AdminDeleteBroadcastRequest = z.object({
  broadcastId: z.string(),
});

const AdminDeleteBroadcastResponse = z.object({});

export type AdminDeleteBroadcastRequest = z.infer<
  typeof AdminDeleteBroadcastRequest
>;

export const AdminDeleteBroadcastDefinition = getFirebaseFunctionDefinition({
  path: "/admin/broadcast/delete",
  reqType: AdminDeleteBroadcastRequest,
  resType: AdminDeleteBroadcastResponse,
  isAuthRequired: true,
});
