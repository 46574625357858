import { z } from "zod";
import { isSchema } from "../utils/isSchema";

export const PUBLIC_TALENT_PLACEMENTS = [
  "HOME_PAGE_FOR_SUPER_REAL",
  "HOME_PAGE_FOR_SUPER_SEXY",
  "VENUS_DISCOVER",
  "VENUS_DISCOVER_FOR_REVIEW",
] as const;

export const PublicTalentPlacement = z.enum(PUBLIC_TALENT_PLACEMENTS);

export type PublicTalentPlacement = z.infer<typeof PublicTalentPlacement>;

export function isPublicTalentPlacement(
  obj: unknown
): obj is PublicTalentPlacement {
  return isSchema(obj, PublicTalentPlacement, "PublicTalentPlacement");
}
