import { z } from "zod";
import { isSchema } from "../utils/isSchema";
import { Money } from "./Money";
import { PayoutStatus } from "./PayoutStatus";
import { PayoutTransferType } from "./PayoutTransferType";
import { Timestamp } from "./Timestamp";

export const Payout = z
  .object({
    id: z.string(),
    status: PayoutStatus,
    talentId: z.string(),
    value: Money,
    executedAt: Timestamp.optional(),
    rejectionReason: z.string().optional(),
    rejectedAt: Timestamp.optional(),
    failedAt: Timestamp.optional(),
    requestedAt: Timestamp,
    updatedByUserId: z.string().optional(),
    taxId: z.string().optional(),
    updatedAt: Timestamp,
    createdAt: Timestamp,
  })
  .and(
    z.union([
      z.object({
        transferType: z.literal("BANK_TRANSFER" satisfies PayoutTransferType),
        accountHolderName: z.string(),
        iban: z.string(),
        bic: z.string(),
      }),
      z.object({
        transferType: z.literal("PAYPAL" satisfies PayoutTransferType),
        payPalEmail: z.string(),
      }),
    ])
  );

export type Payout = z.infer<typeof Payout>;

export function isPayout(obj: unknown): obj is Payout {
  return isSchema(obj, Payout, "Payout");
}
