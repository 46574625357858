import { z } from "zod";
import { isSchema } from "../utils/isSchema";
import { Timestamp } from "./Timestamp";

export const TalentMetricFrame = z.object({
  id: z.string(),
  talentId: z.string(),
  range: z.enum(["TOTAL"] as const),
  chatCount: z.number().int().optional(),
  chatMessageCount: z.number().int().optional(),
  chatWithAuthenticationCount: z.number().int().optional(),
  chatWithPaymentCount: z.number().int().optional(),
  updatedAt: Timestamp,
  createdAt: Timestamp,
});

export type TalentMetricFrame = z.infer<typeof TalentMetricFrame>;

export function isTalentMetricFrame(obj: unknown): obj is TalentMetricFrame {
  return isSchema(obj, TalentMetricFrame, "TalentMetricFrame");
}
