import { z } from "zod";
import { isSchema } from "../utils/isSchema";
import { Gender } from "./Gender";
import { TalentInterest } from "./TalentInterest";
import { TalentPersonalityTrait } from "./TalentPersonalityTrait";
import { TalentPurpose } from "./TalentPurpose";
import { TalentTag } from "./TalentTag";
import { Timestamp } from "./Timestamp";

export const TalentDraft = z.object({
  id: z.string(),
  userId: z.string(),
  name: z.string(),
  tag: TalentTag.optional(),
  gender: Gender.optional(),
  age: z.number().int().positive().optional(),
  purpose: TalentPurpose.optional(),
  personalityTraits: z.array(TalentPersonalityTrait).optional(),
  interests: z.array(TalentInterest).optional(),
  initialChatMessageText: z.string().optional(),
  profilePictureUrl: z.string().optional(),
  referralTalentId: z.string().optional(),
  referralRevenueShare: z.number().min(0).max(1).optional(),
  createdAt: Timestamp,
});

export type TalentDraft = z.infer<typeof TalentDraft>;

export function isTalentDraft(obj: unknown): obj is TalentDraft {
  return isSchema(obj, TalentDraft, "TalentDraft");
}
