import { z } from "zod";
import { isSchema } from "../utils/isSchema";

export const EARNING_CATEGORIES = [
  "CREDITS",
  "REFERRAL",
  "SUBSCRIPTION",
] as const;

export const EarningCategory = z.enum(EARNING_CATEGORIES);

export type EarningCategory = z.infer<typeof EarningCategory>;

export function isEarningCategory(obj: unknown): obj is EarningCategory {
  return isSchema(obj, EarningCategory, "EarningCategory");
}
