import { z } from "zod";
import { isSchema } from "../utils/isSchema";

export const CHAT_PROMPT_MESSAGE_TYPES = ["SYSTEM", "USER", "TALENT"] as const;

export const ChatPromptMessageType = z.enum(CHAT_PROMPT_MESSAGE_TYPES);

export type ChatPromptMessageType = z.infer<typeof ChatPromptMessageType>;

export function isChatPromptMessageType(
  obj: unknown
): obj is ChatPromptMessageType {
  return isSchema(obj, ChatPromptMessageType, "ChatPromptMessageType");
}
