import { z } from "zod";
import { isSchema } from "../utils/isSchema";
import { Dimensions } from "./Dimensions";
import { FileOwnerType } from "./FileOwnerType";
import { FileType } from "./FileType";
import { Timestamp } from "./Timestamp";

export const File = z
  .object({
    id: z.string(),
    ownerType: FileOwnerType,
    ownerId: z.string(),
    filePath: z.string(),
    createdAt: Timestamp,
  })
  .and(
    z.union([
      z.object({
        type: z.literal("IMAGE" satisfies FileType),
        lockedFilePath: z.string(),
        dimensions: Dimensions,
      }),
      z.object({
        type: z.literal("AUDIO" satisfies FileType),
      }),
    ])
  );

export type File = z.infer<typeof File>;

export function isFile(obj: unknown): obj is File {
  return isSchema(obj, File, "File");
}
