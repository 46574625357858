import { z } from "zod";
import { isSchema } from "../utils/isSchema";

export const FUNCTION_NAMES = [
  "generateImageAttachment",
  "generateAudioAttachment",
] as const;

export const FunctionName = z.enum(FUNCTION_NAMES);

export type FunctionName = z.infer<typeof FunctionName>;

export function isFunctionName(obj: unknown): obj is FunctionName {
  return isSchema(obj, FunctionName, "FunctionName");
}
