import { z } from "zod";
import { isSchema } from "../utils/isSchema";

export const CURRENCIES = ["USD"] as const;

export const Currency = z.enum(CURRENCIES);

export type Currency = z.infer<typeof Currency>;

export function isCurrency(obj: unknown): obj is Currency {
  return isSchema(obj, Currency, "Currency");
}
