import { z } from "zod";
import { isSchema } from "../utils/isSchema";

export const OpenAiFunctionSchema = z.object({
  name: z.string(),
  description: z.string(),
  parameters: z.object({
    type: z.string(),
    properties: z.record(
      z.string(),
      z.object({
        type: z.string(),
        enum: z.array(z.string()).optional(),
        description: z.string(),
      })
    ),
    required: z.array(z.string()),
  }),
});

export type OpenAiFunctionSchema = z.infer<typeof OpenAiFunctionSchema>;

export function isOpenAiFunctionSchema(
  obj: unknown
): obj is OpenAiFunctionSchema {
  return isSchema(obj, OpenAiFunctionSchema, "OpenAiFunctionSchema");
}
