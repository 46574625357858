import { z } from "zod";
import { getFirebaseFunctionDefinition } from "../utils/getFirebaseFunctionDefinition";

const CreateTalentDraftRequest = z.object({
  name: z.string(),
  referralTalentTag: z.string().optional(),
});

const CreateTalentDraftResponse = z.object({});

export type CreateTalentDraftRequest = z.infer<typeof CreateTalentDraftRequest>;

export const CreateTalentDraftDefinition = getFirebaseFunctionDefinition({
  path: "/talent-drafts/create",
  reqType: CreateTalentDraftRequest,
  resType: CreateTalentDraftResponse,
  isAuthRequired: true,
});
