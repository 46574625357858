import { z } from "zod";
import { isSchema } from "../utils/isSchema";

export const IapReceiptDataForGoogle = z.object({
  productId: z.string(),
  purchaseTime: z.number(),
  purchaseToken: z.string(),
});

export type IapReceiptDataForGoogle = z.infer<typeof IapReceiptDataForGoogle>;

export function isIapReceiptDataForGoogle(
  obj: unknown
): obj is IapReceiptDataForGoogle {
  return isSchema(obj, IapReceiptDataForGoogle, "IapReceiptDataForGoogle");
}
