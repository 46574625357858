import { z } from "zod";
import { getFirebaseFunctionDefinition } from "../utils/getFirebaseFunctionDefinition";

const UpdateUserRequest = z.object({
  name: z.string().optional(),
  email: z.string().optional(),
});

const UpdateUserResponse = z.object({});

export type UpdateUserRequest = z.infer<typeof UpdateUserRequest>;

export const UpdateUserDefinition = getFirebaseFunctionDefinition({
  path: "/users/update",
  reqType: UpdateUserRequest,
  resType: UpdateUserResponse,
  isAuthRequired: true,
});
