import { z } from "zod";
import { isSchema } from "../utils/isSchema";

export const TALENT_CATEGORIES = [
  "INSTA",
  "CUTE",
  "MATURE",
  "ASIAN",
  "ANIME",
  "GERMAN",
  "EBONY",
  "BLONDE",
  "BRUNETTE",
  "DOMINANT",
  "CURVY",
] as const;

export const TalentCategory = z.enum(TALENT_CATEGORIES);

export type TalentCategory = z.infer<typeof TalentCategory>;

export function isTalentCategory(obj: unknown): obj is TalentCategory {
  return isSchema(obj, TalentCategory, "TalentCategory");
}
