import { z } from "zod";
import { getFirebaseFunctionDefinition } from "../utils/getFirebaseFunctionDefinition";

const SendBroadcastMessagesRequest = z.object({
  broadcastId: z.string(),
  talentId: z.string(),
  chats: z.array(
    z.object({
      id: z.string(),
      talentId: z.string(),
      userId: z.string(),
      lastChatMessage: z
        .object({
          id: z.string(),
          broadcastId: z.string().optional(),
          createdAt: z.string().datetime(),
        })
        .optional(),
      lastUserInteractionAt: z.string().datetime(),
    })
  ),
});

const SendBroadcastMessagesResponse = z.object({});

export type SendBroadcastMessagesRequest = z.infer<
  typeof SendBroadcastMessagesRequest
>;
export type SendBroadcastMessagesResponse = z.infer<
  typeof SendBroadcastMessagesResponse
>;

export const SendBroadcastMessagesDefinition = getFirebaseFunctionDefinition({
  path: "/broadcasts/send-messages",
  reqType: SendBroadcastMessagesRequest,
  resType: SendBroadcastMessagesResponse,
  isAuthRequired: true,
});
