import { z } from "zod";
import { isSchema } from "../utils/isSchema";

export const TEST_GROUP_RECORD = {
  default: ["a", "b"] as const,
} as const;

export type TestGroupName = keyof typeof TEST_GROUP_RECORD;

export const TestGroupRecord = z.object({
  default: z.enum(TEST_GROUP_RECORD.default).optional(),
} satisfies Record<TestGroupName, z.ZodOptional<z.ZodEnum<[string, ...string[]]>>>);

export type TestGroupRecord = z.infer<typeof TestGroupRecord>;

export function isTestGroupRecord(obj: unknown): obj is TestGroupRecord {
  return isSchema(obj, TestGroupRecord, "TestGroupRecord");
}
