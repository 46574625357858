import { z } from "zod";
import { UserRole } from "../types/UserRole";
import { WaitlistStatus } from "../types/WaitlistStatus";
import { getFirebaseFunctionDefinition } from "../utils/getFirebaseFunctionDefinition";

const AdminUpdateUserRequest = z.object({
  id: z.string(),
  name: z.string().optional(),
  email: z.string().optional(),
  role: UserRole,
  waitlistStatus: WaitlistStatus.nullable(),
  credits: z.number().min(0),
});

const AdminUpdateUserResponse = z.object({});

export type AdminUpdateUserRequest = z.infer<typeof AdminUpdateUserRequest>;

export const AdminUpdateUserDefinition = getFirebaseFunctionDefinition({
  path: "/admin/users/update",
  reqType: AdminUpdateUserRequest,
  resType: AdminUpdateUserResponse,
  isAuthRequired: true,
});
