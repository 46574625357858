import { z } from "zod";
import { Money } from "../types/Money";
import { PayoutTransferType } from "../types/PayoutTransferType";
import { getFirebaseFunctionDefinition } from "../utils/getFirebaseFunctionDefinition";

const CreatePayoutRequest = z
  .object({
    talentId: z.string().nonempty(),
    value: Money,
    taxId: z.string(),
  })
  .and(
    z.union([
      z.object({
        transferType: z.literal("BANK_TRANSFER" satisfies PayoutTransferType),
        accountHolderName: z.string().nonempty(),
        iban: z.string().nonempty(),
        bic: z.string().nonempty(),
      }),
      z.object({
        transferType: z.literal("PAYPAL" satisfies PayoutTransferType),
        payPalEmail: z.string().nonempty(),
      }),
    ])
  );

const CreatePayoutResponse = z.object({});

export type CreatePayoutRequest = z.infer<typeof CreatePayoutRequest>;

export const CreatePayoutDefinition = getFirebaseFunctionDefinition({
  path: "/payouts/create",
  reqType: CreatePayoutRequest,
  resType: CreatePayoutResponse,
  isAuthRequired: true,
});
