import { z } from "zod";
import { isSchema } from "../utils/isSchema";
import { Money } from "./Money";
import { ProductPriceType } from "./ProductPriceType";
import { Timestamp } from "./Timestamp";

export const Payment = z.object({
  id: z.string(),
  talentId: z.string().optional(),
  userId: z.string(),
  productId: z.string().optional(),
  productPriceType: ProductPriceType,
  credits: z.number(),
  price: Money,
  originalPrice: z
    .object({
      // TODO: use Money, when we support other currencies than USD
      amount: z.number(),
      currency: z.string(),
    })
    .optional(),
  createdAt: Timestamp,
  stripePaymentIntentId: z.string().optional(),
  stripeInvoiceId: z.string().optional(),
  appleTransactionId: z.string().optional(),
  googleTransactionId: z.string().optional(),
  isSandbox: z.boolean().optional(),
});

export type Payment = z.infer<typeof Payment>;

export function isPayment(obj: unknown): obj is Payment {
  return isSchema(obj, Payment, "Payment");
}
