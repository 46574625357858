import { z } from "zod";
import { isSchema } from "../utils/isSchema";

export const PAYOUT_TRANSFER_TYPES = ["BANK_TRANSFER", "PAYPAL"] as const;

export const PayoutTransferType = z.enum(PAYOUT_TRANSFER_TYPES);

export type PayoutTransferType = z.infer<typeof PayoutTransferType>;

export function isPayoutTransferType(obj: unknown): obj is PayoutTransferType {
  return isSchema(obj, PayoutTransferType, "PayoutTransferType");
}
