import { z } from "zod";
import { getFirebaseFunctionDefinition } from "../utils/getFirebaseFunctionDefinition";

const CreateChatRequest = z.object({
  talentId: z.string(),
});

const CreateChatResponse = z.object({
  chatId: z.string(),
});

export type CreateChatRequest = z.infer<typeof CreateChatRequest>;

export const CreateChatDefinition = getFirebaseFunctionDefinition({
  path: "/chats/create",
  reqType: CreateChatRequest,
  resType: CreateChatResponse,
  isAuthRequired: true,
});
