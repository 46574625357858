import { z } from "zod";
import { isSchema } from "../utils/isSchema";
import { ChatMessage } from "./ChatMessage";
import { TestGroupRecord } from "./TestGroupRecord";
import { Timestamp } from "./Timestamp";

export const Chat = z.object({
  id: z.string(),
  userId: z.string(),
  talentId: z.string(),
  talentName: z.string().optional(),
  talentProfilePictureUrl: z.string().optional(),
  lastChatMessage: ChatMessage.optional(),
  lastUserInteractionAt: Timestamp,
  hasUserInteraction: z.boolean().optional(),
  hasUserInteractionWithPaidCredits: z.boolean().optional(),
  hasUserInteractionWithSubscription: z.boolean().optional(),
  hasUserInteractionWithNonAnonymousUser: z.boolean().optional(),
  hasUserInteractionWithManager: z.boolean().optional(),
  hasUserInteractionWithUnlockImageAttachment: z.boolean().optional(),
  chatMessageCount: z.number().optional(),
  testGroupRecord: TestGroupRecord.optional(),
  timezone: z.string().optional(),
  archivedAt: Timestamp.optional(),
  updatedAt: Timestamp.optional(),
  createdAt: Timestamp,
});

export type Chat = z.infer<typeof Chat>;

export function isChat(obj: unknown): obj is Chat {
  return isSchema(obj, Chat, "Chat");
}
