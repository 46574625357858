import { z } from "zod";
import { isSchema } from "../utils/isSchema";

export const FaqItem = z.object({
  question: z.string(),
  answer: z.string(),
});

export type FaqItem = z.infer<typeof FaqItem>;

export function isFaqItem(obj: unknown): obj is FaqItem {
  return isSchema(obj, FaqItem, "FaqItem");
}
