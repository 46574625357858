import { z } from "zod";
import { Voice } from "../types/Voice";
import { getFirebaseFunctionDefinition } from "../utils/getFirebaseFunctionDefinition";

const AdminUpdateAppRequest = z.object({
  chatPromptTemplate: z.string().nonempty(),
  chatPromptTemplateForFaq: z.string().nonempty(),
  talentChatPromptTemplate: z.string().nonempty(),
  referralRevenueShare: z.number().min(0).max(1),
  llmFunctionTemperature: z.number().min(0).max(1),
  functionSchemaConfig: z.object({
    generateImageAttachment: z.object({
      name: z
        .string()
        .nonempty()
        .refine(
          (name) => /^[a-zA-Z_]+$/.test(name),
          "FunctionName contains invalid characters"
        ),
      description: z.string().nonempty(),
      contextDescription: z.string().nonempty(),
      successTextDescription: z.string().nonempty(),
      failureTextDescription: z.string().nonempty(),
    }),
    generateAudioAttachment: z.object({
      name: z
        .string()
        .nonempty()
        .refine(
          (name) => /^[a-zA-Z_]+$/.test(name),
          "FunctionName contains invalid characters"
        ),
      description: z.string().nonempty(),
      successTextDescription: z.string().nonempty(),
      failureTextDescription: z.string().nonempty(),
    }),
  }),
  voices: z.array(Voice),
});

const AdminUpdateAppResponse = z.object({});

export type AdminUpdateAppRequest = z.infer<typeof AdminUpdateAppRequest>;

export const AdminUpdateAppDefinition = getFirebaseFunctionDefinition({
  path: "/admin/app/update",
  reqType: AdminUpdateAppRequest,
  resType: AdminUpdateAppResponse,
  isAuthRequired: true,
});
