import { z } from "zod";
import { getFirebaseFunctionDefinition } from "../utils/getFirebaseFunctionDefinition";

const CreateCheckoutSessionRequest = z.object({
  talentId: z.string(),
  productId: z.string(),
  toltReferral: z.string().optional(),
  successUrl: z.string(),
  cancelUrl: z.string(),
});

const CreateCheckoutSessionResponse = z.object({
  sessionId: z.string(), // @deprecated: remove this in favor of sessionUrl after 10.12.2023
  sessionUrl: z.string(),
});

export type CreateCheckoutSessionRequest = z.infer<
  typeof CreateCheckoutSessionRequest
>;

export const CreateCheckoutSessionDefinition = getFirebaseFunctionDefinition({
  path: "/checkout/session/create",
  reqType: CreateCheckoutSessionRequest,
  resType: CreateCheckoutSessionResponse,
  isAuthRequired: true,
});
