import { z } from "zod";
import { isSchema } from "../utils/isSchema";

export const MODEL_VERSION_STATUSES = [
  "UPLOADING_TRAINING_FILE",
  "FINE_TUNING",
  "SUCCEEDED",
  "FAILED",
] as const;

export const ModelVersionStatus = z.enum(MODEL_VERSION_STATUSES);

export type ModelVersionStatus = z.infer<typeof ModelVersionStatus>;

export function isModelVersionStatus(obj: unknown): obj is ModelVersionStatus {
  return isSchema(obj, ModelVersionStatus, "ModelVersionStatus");
}
