import { z } from "zod";
import { ModelBase } from "../types/ModelBase";
import { getFirebaseFunctionDefinition } from "../utils/getFirebaseFunctionDefinition";

export const RefreshModelVersionStatusRequest = z.object({
  modelId: z.string(),
  modelBase: ModelBase,
  modelVersionId: z.string(),
  externalJobId: z.string(),
});

export const RefreshModelVersionStatusResponse = z.undefined();

export type RefreshModelVersionStatusRequest = z.infer<
  typeof RefreshModelVersionStatusRequest
>;

export const RefreshModelVersionStatusDefinition =
  getFirebaseFunctionDefinition({
    path: "/models/versions/status",
    reqType: RefreshModelVersionStatusRequest,
    resType: RefreshModelVersionStatusResponse,
    isAuthRequired: false,
  });
