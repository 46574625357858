import { z } from "zod";
import { isSchema } from "../utils/isSchema";
import { Gender } from "./Gender";
import { PublicTalentPlacement } from "./PublicTalentPlacement";
import { TalentCategory } from "./TalentCategory";
import { Timestamp } from "./Timestamp";

export const PublicTalent = z.object({
  id: z.string(),
  type: z.enum(["REAL", "AI"]).optional(),
  isVisibleRecord: z.record(PublicTalentPlacement, z.boolean()).optional(),
  label: z.string().optional(),
  name: z.string(),
  age: z.number().min(18),
  gender: Gender,
  tag: z.string(),
  profilePictureUrl: z.string(),
  order: z.number(),
  categories: z.array(TalentCategory).optional(),
  instagramFollowerCount: z.number().optional(),
  instagramLink: z.string().optional(),
  subline: z.string().optional(),
  createdAt: Timestamp,
});

export type PublicTalent = z.infer<typeof PublicTalent>;

export function isPublicTalent(obj: unknown): obj is PublicTalent {
  return isSchema(obj, PublicTalent, "PublicTalent");
}
