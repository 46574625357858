import { z } from "zod";
import { isSchema } from "../utils/isSchema";

export const SOCIAL_PLATFORMS = ["INSTAGRAM", "TIKTOK", "X"] as const;

export const SocialPlatform = z.enum(SOCIAL_PLATFORMS);

export type SocialPlatform = z.infer<typeof SocialPlatform>;

export function isSocialPlatform(obj: unknown): obj is SocialPlatform {
  return isSchema(obj, SocialPlatform, "SocialPlatform");
}
