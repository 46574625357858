import { z } from "zod";
import { isSchema } from "../utils/isSchema";

export const MODEL_BASES = [
  "openai/gpt-3.5-turbo",
  "anyscale/llama2-13b",
] as const;

export const ModelBase = z.enum(MODEL_BASES);

export type ModelBase = z.infer<typeof ModelBase>;

export function isModelBase(obj: unknown): obj is ModelBase {
  return isSchema(obj, ModelBase, "ModelBase");
}
