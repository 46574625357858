import { z } from "zod";
import { isSchema } from "../utils/isSchema";
import { LlmType } from "./LlmType";
import { StaticAttachment } from "./StaticAttachment";
import { TalentInterest } from "./TalentInterest";
import { TalentPersonalityTrait } from "./TalentPersonalityTrait";
import { TalentPurpose } from "./TalentPurpose";
import { Timestamp } from "./Timestamp";
import { Voice } from "./Voice";

export const TalentConfig = z.object({
  id: z.string(),
  voice: Voice.optional(),
  llmType: LlmType,
  llmTemperature: z.number().min(0),
  llmModelId: z.string().optional(),
  llmFineTunedModelId: z.string().optional(),
  faqId: z.string().optional(),
  hasCustomChatPrompt: z.boolean().optional(),
  chatPrompt: z.string(),
  chatCount: z.number(),
  facts: z.string(),
  purpose: TalentPurpose.optional(),
  personalityTraits: z.array(TalentPersonalityTrait).optional(),
  interests: z.array(TalentInterest).optional(),
  chatExampleCount: z.number(),
  chatExamples: z.array(
    z.object({
      requestText: z.string(),
      responseText: z.string(),
    })
  ),
  staticAttachments: z.array(StaticAttachment),
  revenueShare: z.number().min(0).max(1),
  referralTalentId: z.string().optional(),
  referralRevenueShareForInviter: z.number().min(0).max(1).optional(),
  referralRevenueShareForInvitees: z.number().min(0).max(1).optional(),
  accountHolderName: z.string().optional(),
  iban: z.string().optional(),
  bic: z.string().optional(),
  taxId: z.string().optional(),
  payPalEmail: z.string().optional(),
  totalPayoutAmount: z.number().optional(),
  createdAt: Timestamp,
});

export type TalentConfig = z.infer<typeof TalentConfig>;

export function isTalentConfig(obj: unknown): obj is TalentConfig {
  return isSchema(obj, TalentConfig, "TalentConfig");
}
