import { z } from "zod";
import { Attachment } from "../types/Attachment";
import { Gender } from "../types/Gender";
import { LlmType } from "../types/LlmType";
import { StaticAttachment } from "../types/StaticAttachment";
import { TalentInterest } from "../types/TalentInterest";
import { TalentPersonalityTrait } from "../types/TalentPersonalityTrait";
import { TalentPurpose } from "../types/TalentPurpose";
import { Voice } from "../types/Voice";
import { getFirebaseFunctionDefinition } from "../utils/getFirebaseFunctionDefinition";

const AdminUpdateTalentRequest = z.object({
  talentId: z.string(),
  name: z.string(),
  tag: z.string(),
  profilePictureUrl: z.string().optional(),
  gender: Gender,
  age: z.number().int().positive(),
  voice: Voice,
  creditsToSendMessage: z.number(),
  creditsToUnlockImage: z.number(),
  creditsToUnlockAudio: z.number(),
  creditsToUnlockFirstImage: z.number().nullable(),
  revenueShare: z.number(),
  isAdult: z.boolean(),
  hasCustomChatPrompt: z.boolean(),
  chatPrompt: z.string(),
  facts: z.string(),
  purpose: TalentPurpose,
  personalityTraits: z.array(TalentPersonalityTrait),
  interests: z.array(TalentInterest),
  chatExampleCount: z.number(),
  chatExamples: z.array(
    z.object({
      requestText: z.string(),
      responseText: z.string(),
    })
  ),
  initialChatMessages: z.array(
    z.object({
      id: z.string(),
      text: z.string(),
      attachments: z.array(Attachment).optional(),
    })
  ),
  staticAttachments: z.array(StaticAttachment),
  llmType: LlmType,
  llmTemperature: z.number().min(0),
  llmModelId: z.string(),
  llmFineTunedModelId: z.string(),
  faqId: z.string(),
  subscriptionProductId: z.string(),
  referralRevenueShareForInvitees: z.number().min(0).max(1).optional(),
  chatMessageForAuth: z.string(),
  chatMessageForCheckout: z.string(),
  chatMessageForSubscription: z.string(),
  chatMessageSuggestions: z.array(
    z.object({
      id: z.string(),
      text: z.string(),
      answer: z.string().optional(),
    })
  ),
});

const AdminUpdateTalentResponse = z.object({});

export type AdminUpdateTalentRequest = z.infer<typeof AdminUpdateTalentRequest>;

export const AdminUpdateTalentDefinition = getFirebaseFunctionDefinition({
  path: "/admin/talents/update",
  reqType: AdminUpdateTalentRequest,
  resType: AdminUpdateTalentResponse,
  isAuthRequired: true,
});
