import { z } from "zod";
import { isSchema } from "../utils/isSchema";

export const TalentTag = z
  .string()
  .trim()
  .toLowerCase()
  .min(3)
  .max(30)
  .refine(
    (tag) => /^[a-zA-Z0-9._\\-]+$/.test(tag),
    "Tag contains invalid characters"
  )
  .refine(
    (tag) => !tag.includes(".."),
    "Tag is not allowed to contain two or more consecutive dots"
  )
  .refine((tag) => !tag.startsWith(".") && !tag.endsWith("."), {
    message: "Tag is not allowed to start or end with a dot",
  });

export type TalentTag = z.infer<typeof TalentTag>;

export function isTalentTag(obj: unknown): obj is TalentTag {
  return isSchema(obj, TalentTag, "TalentTag");
}
