import { z } from "zod";
import { getFirebaseFunctionDefinition } from "../utils/getFirebaseFunctionDefinition";

const RequestCreditRewardForAppUsageRequest = z.object({});
const RequestCreditRewardForAppUsageResponse = z.object({
  creditRewardForAppUsage: z.number(),
});

export type RequestCreditRewardForAppUsageRequest = z.infer<
  typeof RequestCreditRewardForAppUsageRequest
>;

export const RequestCreditRewardForAppUsageDefinition =
  getFirebaseFunctionDefinition({
    path: "/users/request-credit-reward-for-app-usage",
    reqType: RequestCreditRewardForAppUsageRequest,
    resType: RequestCreditRewardForAppUsageResponse,
    isAuthRequired: true,
  });
