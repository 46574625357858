import { z } from "zod";
import { StaticAttachment } from "../types/StaticAttachment";
import { getFirebaseFunctionDefinition } from "../utils/getFirebaseFunctionDefinition";

const UpdateTalentConfigUpdateStaticAttachmentRequest = z.object({
  id: z.string(),
  staticAttachment: StaticAttachment,
});

const UpdateTalentConfigUpdateStaticAttachmentResponse = z.object({
  staticAttachmentId: z.string(),
});

export type UpdateTalentConfigUpdateStaticAttachmentRequest = z.infer<
  typeof UpdateTalentConfigUpdateStaticAttachmentRequest
>;

export const UpdateTalentConfigUpdateStaticAttachmentDefinition =
  getFirebaseFunctionDefinition({
    path: "/talent-configs/static-attachments/update",
    reqType: UpdateTalentConfigUpdateStaticAttachmentRequest,
    resType: UpdateTalentConfigUpdateStaticAttachmentResponse,
    isAuthRequired: true,
  });
