import { z } from "zod";
import { isSchema } from "../utils/isSchema";

export const PRODUCT_PRICE_TYPE = [
  "SUBSCRIPTION",
  "ONE_TIME",
  "APPLE_ONE_TIME",
  "GOOGLE_ONE_TIME",
] as const;

export const ProductPriceType = z.enum(PRODUCT_PRICE_TYPE);

export type ProductPriceType = z.infer<typeof ProductPriceType>;

export function isProductPriceType(obj: unknown): obj is ProductPriceType {
  return isSchema(obj, ProductPriceType, "ProductPriceType");
}
