import { z } from "zod";
import { isSchema } from "../utils/isSchema";

const DEPRECATED_TALENT_INTERESTS = [
  "BAKING",
  "BIRDWATCHING",
  "CAMPING",
  "CRAFTING",
  "CYCLING",
  "FISHING",
  "GARDENING",
  "HIKING",
  "MEDITATION",
  "PAINTING",
  "PHOTOGRAPHY",
  "READING",
  "SINGING",
  "SKATEBOARDING",
  "STARGAZING",
  "SURFING",
  "SWIMMING",
  "TRAVELING",
  "WOODWORKING",
  "WRITING",
] as const;

export const CURRENT_TALENT_INTERESTS = [
  "ANIME",
  "ART",
  "BEACH",
  "CATS",
  "COOKING",
  "DANCING",
  "DATING",
  "DESIGN",
  "DOGS",
  "EDM",
  "FAMILY",
  "FASHION",
  "GYM",
  "HIP_HOP",
  "PARTYING",
  "PHILOSOPHY",
  "ROMCOMS",
  "RUNNING",
  "VIDEO_GAMES",
  "WINTERSPORTS",
  "YOGA",
] as const;

export const TALENT_INTERESTS = [
  ...CURRENT_TALENT_INTERESTS,
  ...DEPRECATED_TALENT_INTERESTS,
] as const;

export const CurrentTalentInterest = z.enum(CURRENT_TALENT_INTERESTS);
export const DeprecatedTalentInterest = z.enum(DEPRECATED_TALENT_INTERESTS);

export const TalentInterest = z.union([
  CurrentTalentInterest,
  DeprecatedTalentInterest,
]);

export type TalentInterest = z.infer<typeof TalentInterest>;
export type CurrentTalentInterest = z.infer<typeof CurrentTalentInterest>;

export function isTalentInterest(obj: unknown): obj is TalentInterest {
  return isSchema(obj, TalentInterest, "TalentInterest");
}
