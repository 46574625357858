import { z } from "zod";
import { isSchema } from "../utils/isSchema";

export const BROADCAST_STATUSES = [
  "SCHEDULED",
  "PREPARING",
  "SENDING_MESSAGES",
  "COMPLETED",
  "DELETED",
] as const;

export const BroadcastStatus = z.enum(BROADCAST_STATUSES);

export type BroadcastStatus = z.infer<typeof BroadcastStatus>;

export function isBroadcastStatus(obj: unknown): obj is BroadcastStatus {
  return isSchema(obj, BroadcastStatus, "BroadcastStatus");
}
