import { z } from "zod";
import { getFirebaseFunctionDefinition } from "../utils/getFirebaseFunctionDefinition";

const CreateModelVersionRequest = z.object({
  modelId: z.string(),
});

const CreateModelVersionResponse = z.object({
  modelVersionId: z.string(),
});

export type CreateModelVersionRequest = z.infer<
  typeof CreateModelVersionRequest
>;

export const CreateModelVersionDefinition = getFirebaseFunctionDefinition({
  path: "/models/versions/create",
  reqType: CreateModelVersionRequest,
  resType: CreateModelVersionResponse,
  isAuthRequired: true,
});
