import { z } from "zod";
import { isSchema } from "../utils/isSchema";
import { IapProvider } from "./IapProvider";

/**
 * In-App Purchase Receipt
 *
 * https://github.com/voltrue2/in-app-purchase?tab=readme-ov-file#receipt-data-format
 */
export const IapReceipt = z.union([
  z.object({
    provider: z.literal("APPLE" satisfies IapProvider),
    /**
     * An Apple's receipt is a base64 encoded string.
     */
    data: z.string(),
  }),
  z.object({
    provider: z.literal("GOOGLE" satisfies IapProvider),
    /**
     * Signed data JSON string.
     */
    data: z.string(),
    /**
     * Base 64 encoded signature string
     */
    signature: z.string(),
  }),
]);

export type IapReceipt = z.infer<typeof IapReceipt>;

export function isIapReceipt(obj: unknown): obj is IapReceipt {
  return isSchema(obj, IapReceipt, "IapReceipt");
}
