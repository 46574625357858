import { z } from "zod";
import { isSchema } from "../utils/isSchema";

export const ATTACHMENT_TYPES = ["IMAGE", "AUDIO"] as const;

export const AttachmentType = z.enum(ATTACHMENT_TYPES);

export type AttachmentType = z.infer<typeof AttachmentType>;

export function isAttachmentType(obj: unknown): obj is AttachmentType {
  return isSchema(obj, AttachmentType, "AttachmentType");
}
