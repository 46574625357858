import { z } from "zod";
import { getFirebaseFunctionDefinition } from "../utils/getFirebaseFunctionDefinition";

const MigrateDataRequest = z.object({});

const MigrateDataResponse = z.object({});

export type MigrateDataRequest = z.infer<typeof MigrateDataRequest>;

export const MigrateDataDefinition = getFirebaseFunctionDefinition({
  path: "/migrate",
  reqType: MigrateDataRequest,
  resType: MigrateDataResponse,
  isAuthRequired: true,
});
