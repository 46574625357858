import { z } from "zod";
import {
  MAX_CHAT_PROMPT_LENGTH,
  MIN_CREDITS_TO_SEND_MESSAGE,
  MIN_CREDITS_TO_UNLOCK_AUDIO,
  MIN_CREDITS_TO_UNLOCK_IMAGE,
} from "../consts";
import { Attachment } from "../types/Attachment";
import { Gender } from "../types/Gender";
import { TalentInterest } from "../types/TalentInterest";
import { TalentPersonalityTrait } from "../types/TalentPersonalityTrait";
import { TalentPurpose } from "../types/TalentPurpose";
import { Voice } from "../types/Voice";
import { getFirebaseFunctionDefinition } from "../utils/getFirebaseFunctionDefinition";

const UpdateTalentRequest = z.object({
  id: z.string(),
  name: z.string().optional(),
  gender: Gender.optional(),
  age: z.number().int().min(18).max(1000).optional(),
  profilePictureUrl: z.string().optional(),
  hasCustomChatPrompt: z.boolean().optional(),
  chatPrompt: z.string().max(MAX_CHAT_PROMPT_LENGTH).optional(),
  purpose: TalentPurpose.optional(),
  personalityTraits: z.array(TalentPersonalityTrait).min(1).max(3).optional(),
  interests: z.array(TalentInterest).min(1).max(5).optional(),
  initialChatMessages: z
    .array(
      z.object({
        id: z.string(),
        text: z.string(),
        attachments: z.array(Attachment).optional(),
      })
    )
    .optional(),
  chatMessageSuggestions: z
    .array(
      z.object({
        id: z.string(),
        text: z.string().nonempty(),
        answer: z.string().optional(),
      })
    )
    .optional(),
  creditsToSendMessage: z
    .number()
    .int()
    .min(MIN_CREDITS_TO_SEND_MESSAGE)
    .optional(),
  creditsToUnlockImage: z
    .number()
    .int()
    .min(MIN_CREDITS_TO_UNLOCK_IMAGE)
    .optional(),
  creditsToUnlockAudio: z
    .number()
    .int()
    .min(MIN_CREDITS_TO_UNLOCK_AUDIO)
    .optional(),
  voice: Voice.optional(),
  facts: z.string().optional(),
});

const UpdateTalentResponse = z.object({});

export type UpdateTalentRequest = z.infer<typeof UpdateTalentRequest>;

export const UpdateTalentDefinition = getFirebaseFunctionDefinition({
  path: "/talents/update",
  reqType: UpdateTalentRequest,
  resType: UpdateTalentResponse,
  isAuthRequired: true,
});
