import { z } from "zod";
import { isSchema } from "../utils/isSchema";
import { Attachment } from "./Attachment";
import { Gender } from "./Gender";
import { Timestamp } from "./Timestamp";

export const Talent = z.object({
  id: z.string(),
  name: z.string(),
  tag: z.string(),
  profilePictureUrl: z.string().optional(),
  gender: Gender,
  age: z.number().int().positive(),
  isAdult: z.boolean(),
  subscriptionProductId: z.string().optional(),
  creditsToSendMessage: z.number(),
  creditsToUnlockImage: z.number(),
  creditsToUnlockAudio: z.number(),
  creditsToUnlockFirstImage: z.number().optional(),
  chatMessageForAuth: z.string().optional(),
  chatMessageForCheckout: z.string().optional(),
  chatMessageForSubscription: z.string().optional(),
  chatMessageSuggestions: z
    .array(
      z.object({
        id: z.string(),
        text: z.string(),
        answer: z.string().optional(),
      })
    )
    .optional(),
  initialChatMessages: z.array(
    z.object({
      id: z.string(),
      text: z.string(),
      attachments: z.array(Attachment).optional(),
    })
  ),
  createdAt: Timestamp,
});

export type Talent = z.infer<typeof Talent>;

export function isTalent(obj: unknown): obj is Talent {
  return isSchema(obj, Talent, "Talent");
}
