import {
  TEST_GROUP_RECORD,
  TestGroupName,
  TestGroupRecord,
} from "../types/TestGroupRecord";

/**
 * Get the test group value for a user.
 * @param name The name of the test group.
 * @param userId The user ID.
 * @returns The test group value.
 */
export const getTestGroup = <
  TName extends TestGroupName,
  TValue = TestGroupRecord[TName] & {}
>(
  name: TName,
  userId?: string
): TValue => {
  // Get the values for the test group
  // e.g. values = ["foo", "bar"]
  const values = TEST_GROUP_RECORD[name];

  // If there is no user ID, return the first value
  // e.g. "foo"
  if (!userId) return values[0] as TValue;

  // Split the user ID into an array and remove the dashes
  // e.g. "ab-1" => ["a", "b", "1"]
  const letters = userId.split("").filter((letter) => letter !== "-");

  // Sum the char codes of the letters
  // e.g. ["a", "b", "1"] => 97 + 98 + 49 => 244
  const sum = letters.reduce(
    (result, letter) => result + (letter.charCodeAt(0) || 0),
    0
  );

  // Get the index of the value to return
  // e.g. 244 % 2 => 0
  // e.g. 245 % 2 => 1
  const index = sum % values.length;

  // Return the value at the index
  // e.g. 0 => "foo"
  return values[index] as TValue;
};
