import { z } from "zod";
import { getFirebaseFunctionDefinition } from "../utils/getFirebaseFunctionDefinition";

const DeleteUserRequest = z.object({});

const DeleteUserResponse = z.object({});

export type DeleteUserRequest = z.infer<typeof DeleteUserRequest>;

export const DeleteUserDefinition = getFirebaseFunctionDefinition({
  path: "/user/delete",
  reqType: DeleteUserRequest,
  resType: DeleteUserResponse,
  isAuthRequired: true,
});
