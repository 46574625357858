import { z } from "zod";
import { getFirebaseFunctionDefinition } from "../utils/getFirebaseFunctionDefinition";

const UpdateTalentConfigRemoveStaticAttachmentRequest = z.object({
  id: z.string(),
  staticAttachmentId: z.string(),
});

const UpdateTalentConfigRemoveStaticAttachmentResponse = z.object({});

export type UpdateTalentConfigRemoveStaticAttachmentRequest = z.infer<
  typeof UpdateTalentConfigRemoveStaticAttachmentRequest
>;

export const UpdateTalentConfigRemoveStaticAttachmentDefinition =
  getFirebaseFunctionDefinition({
    path: "/talent-configs/static-attachments/remove",
    reqType: UpdateTalentConfigRemoveStaticAttachmentRequest,
    resType: UpdateTalentConfigRemoveStaticAttachmentResponse,
    isAuthRequired: true,
  });
