import { z } from "zod";
import { getFirebaseFunctionDefinition } from "../utils/getFirebaseFunctionDefinition";

const CreateCannyTokenRequest = z.object({});

const CreateCannyTokenResponse = z.object({
  cannyToken: z.string(),
});

export type CreateCannyTokenRequest = z.infer<typeof CreateCannyTokenRequest>;
export type CreateCannyTokenResponse = z.infer<typeof CreateCannyTokenResponse>;

export const CreateCannyTokenDefinition = getFirebaseFunctionDefinition({
  path: "/auth/create-canny-token",
  reqType: CreateCannyTokenRequest,
  resType: CreateCannyTokenResponse,
  isAuthRequired: true,
});
