import { z } from "zod";
import { isSchema } from "../utils/isSchema";
import { CrmTag } from "./CrmTag";
import { TestGroupRecord } from "./TestGroupRecord";
import { Timestamp } from "./Timestamp";
import { UserRole } from "./UserRole";
import { WaitlistStatus } from "./WaitlistStatus";

export const User = z.object({
  id: z.string(),
  name: z.string().optional(),
  credits: z.number(),
  paidCredits: z.number().optional(),
  email: z.string().optional(),
  instagram: z.string().optional(),
  tiktok: z.string().optional(),
  x: z.string().optional(),
  crmTags: z.array(CrmTag).optional(),
  lastTalentId: z.string().optional(),
  hasReceivedCreditRewardForSignUp: z.boolean().optional(),
  hasReceivedCreditRewardForAppUsage: z.boolean().optional(),
  managedTalentIds: z.array(z.string()).optional(),
  role: UserRole,
  waitlistStatus: WaitlistStatus.optional(),
  testGroupRecord: TestGroupRecord.optional(),
  timezone: z.string().optional(),
  createdAt: Timestamp,
  deletedAt: Timestamp.optional(),
});

export type User = z.infer<typeof User>;

export function isUser(obj: unknown): obj is User {
  return isSchema(obj, User, "User");
}
