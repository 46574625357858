import { z } from "zod";
import { getFirebaseFunctionDefinition } from "../utils/getFirebaseFunctionDefinition";

const ArchiveChatRequest = z.object({
  chatId: z.string(),
});

const ArchiveChatResponse = z.object({});

export type ArchiveChatRequest = z.infer<typeof ArchiveChatRequest>;

export const ArchiveChatDefinition = getFirebaseFunctionDefinition({
  path: "/chats/archive",
  reqType: ArchiveChatRequest,
  resType: ArchiveChatResponse,
  isAuthRequired: true,
});
