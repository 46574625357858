import { z } from "zod";
import { isSchema } from "../utils/isSchema";
import { ModelBase } from "./ModelBase";
import { ModelTrainingExample } from "./ModelTrainingExample";
import { ModelVersion } from "./ModelVersion";
import { Timestamp } from "./Timestamp";

export const Model = z.object({
  id: z.string(),

  // used before training a version
  name: z.string(),
  type: z.literal("CHAT"),
  talentId: z.string().nullable(),
  base: ModelBase,
  trainingExamples: z.array(ModelTrainingExample),

  // used after training to make requests against llm apis
  defaultVersionId: z.string().optional(), // @deprecated use defaultVersion instead
  defaultFineTunedModelId: z.string().optional(), // @deprecated use defaultVersion instead
  defaultVersion: ModelVersion.optional(),
  createdAt: Timestamp,
});

export type Model = z.infer<typeof Model>;

export function isModel(obj: unknown): obj is Model {
  return isSchema(obj, Model, "Model");
}
