import { z } from "zod";
import { isSchema } from "../utils/isSchema";

export const StripeClientReference = z.object({
  userId: z.string(),
  talentId: z.string(),
});

export type StripeClientReference = z.infer<typeof StripeClientReference>;

export function isStripeClientReference(
  obj: unknown
): obj is StripeClientReference {
  return isSchema(obj, StripeClientReference, "StripeClientReference");
}
