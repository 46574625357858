import { z } from "zod";
import { isSchema } from "../utils/isSchema";

export const USER_ROLES = ["USER", "ADMIN"] as const;

export const UserRole = z.enum(USER_ROLES);

export type UserRole = z.infer<typeof UserRole>;

export function isUserRole(obj: unknown): obj is UserRole {
  return isSchema(obj, UserRole, "UserRole");
}
