import { z } from "zod";
import { isSchema } from "../utils/isSchema";

export const PRODUCT_BILLING_PERIOD_TYPES = [
  "DAYS",
  "WEEKS",
  "MONTHS",
] as const;

export const ProductBillingPeriodType = z.enum(PRODUCT_BILLING_PERIOD_TYPES);

export type ProductBillingPeriodType = z.infer<typeof ProductBillingPeriodType>;

export function isProductBillingPeriodType(
  obj: unknown
): obj is ProductBillingPeriodType {
  return isSchema(obj, ProductBillingPeriodType, "ProductBillingPeriodType");
}
