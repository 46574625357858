import { ScopeContext } from "@sentry/types";
import { toast } from "react-hot-toast";
import { ENVIRONMENT } from "../consts/ENVIRONMENT";

export type ErrorContext = Partial<ScopeContext>;

export function handleError(error: unknown) {
  if (!(error instanceof Error)) return;

  handleErrorWithoutToast(error);
  toast.error(error.message);
}

export function handleErrorWithoutToast(error: unknown) {
  if (!(error instanceof Error)) return;

  console.error(error);

  if (ENVIRONMENT.nodeEnv !== "production") return;

  // TODO: add Sentry!
  // Sentry.captureException(error);
}
