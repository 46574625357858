import { z } from "zod";
import { isSchema } from "../utils/isSchema";
import { ChatPromptMessageType } from "./ChatPromptMessageType";

export const ChatPromptMessage = z.object({
  type: ChatPromptMessageType,
  text: z.string(),
});

export type ChatPromptMessage = z.infer<typeof ChatPromptMessage>;

export function isChatPromptMessage(obj: unknown): obj is ChatPromptMessage {
  return isSchema(obj, ChatPromptMessage, "ChatPromptMessage");
}
