import { z } from "zod";
import { isSchema } from "../utils/isSchema";
import { ModelMessageRole } from "./ModelMessageRole";

export const ModelMessage = z.object({
  role: ModelMessageRole,
  content: z.string(),
});

export type ModelMessage = z.infer<typeof ModelMessage>;

export function isModelMessage(obj: unknown): obj is ModelMessage {
  return isSchema(obj, ModelMessage, "ModelMessage");
}
