import { z } from "zod";
import { isSchema } from "../utils/isSchema";
import { FunctionName } from "./FunctionName";
import { LlmType } from "./LlmType";
import { Money } from "./Money";

export const AppConfig = z.object({
  id: z.string(),
  chatPromptTemplate: z.string(),
  chatPromptTemplateForFaq: z.string(),
  talentChatPromptTemplate: z.string(),
  defaultCreditsToSendMessage: z.number(),
  defaultCreditsToUnlockImage: z.number(),
  defaultCreditsToUnlockAudio: z.number(),
  chatPromptMessageLimit: z.number(),
  attachmentAudioChance: z.number(), // number between 0.0 and 1.0
  pricePerCredit: Money,
  fineTuningModelId: z.string().optional(),
  overwrittenLlmType: LlmType.optional().nullable(),
  llmFunctionTemperature: z.number().min(0).max(1),
  referralRevenueShare: z.number().min(0).max(1),
  maxCompletionTokens: z.number(),
  faqMinScore: z.number(),
  functionSchemaConfig: z.object({
    generateImageAttachment: z.object({
      name: z.string(),
      description: z.string(),
      contextDescription: z.string(),
      successTextDescription: z.string(),
      failureTextDescription: z.string(),
    }),
    generateAudioAttachment: z.object({
      name: z.string(),
      description: z.string(),
      successTextDescription: z.string(),
      failureTextDescription: z.string(),
    }),
  } satisfies Record<FunctionName, unknown>),
  /**
   * This is a record of the LLM types and whether they are healthy or not.
   * key is `llmType`, value is `isHealthy`
   */
  isModelHealthyRecord: z.record(LlmType, z.boolean()).optional(),
});

export type AppConfig = z.infer<typeof AppConfig>;

export function isAppConfig(obj: unknown): obj is AppConfig {
  return isSchema(obj, AppConfig, "AppConfig");
}
