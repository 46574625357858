import { z } from "zod";
import { getFirebaseFunctionDefinition } from "../utils/getFirebaseFunctionDefinition";

const CalculateTotalEarningFrameRequest = z.object({
  talentId: z.string(),
});

const CalculateTotalEarningFrameResponse = z.object({});

export type CalculateTotalEarningFrameRequest = z.infer<
  typeof CalculateTotalEarningFrameRequest
>;

export type CalculateTotalEarningFrameResponse = z.infer<
  typeof CalculateTotalEarningFrameResponse
>;

export const CalculateTotalEarningFrameDefinition =
  getFirebaseFunctionDefinition({
    path: "/earningFrames/total/calculate",
    reqType: CalculateTotalEarningFrameRequest,
    resType: CalculateTotalEarningFrameResponse,
    isAuthRequired: true,
  });
