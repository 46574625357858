import { z } from "zod";
import { getFirebaseFunctionDefinition } from "../utils/getFirebaseFunctionDefinition";

const PublishTalentDraftRequest = z.object({
  id: z.string(),
});

const PublishTalentDraftResponse = z.object({});

export type PublishTalentDraftRequest = z.infer<
  typeof PublishTalentDraftRequest
>;

export const PublishTalentDraftDefinition = getFirebaseFunctionDefinition({
  path: "/talent-drafts/publish",
  reqType: PublishTalentDraftRequest,
  resType: PublishTalentDraftResponse,
  isAuthRequired: true,
});
