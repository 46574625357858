import { z } from "zod";
import { Gender } from "../types/Gender";
import { TalentInterest } from "../types/TalentInterest";
import { TalentPersonalityTrait } from "../types/TalentPersonalityTrait";
import { TalentPurpose } from "../types/TalentPurpose";
import { TalentTag } from "../types/TalentTag";
import { getFirebaseFunctionDefinition } from "../utils/getFirebaseFunctionDefinition";

const UpdateTalentDraftRequest = z.object({
  id: z.string(),
  name: z.string().optional(),
  tag: TalentTag.optional(),
  gender: Gender.optional(),
  age: z.number().int().positive().optional(),
  purpose: TalentPurpose.optional(),
  personalityTraits: z.array(TalentPersonalityTrait).optional(),
  interests: z.array(TalentInterest).optional(),
  initialChatMessageText: z.string().optional(),
  profilePictureUrl: z.string().optional(),
});

const UpdateTalentDraftResponse = z.object({});

export type UpdateTalentDraftRequest = z.infer<typeof UpdateTalentDraftRequest>;

export const UpdateTalentDraftDefinition = getFirebaseFunctionDefinition({
  path: "/talent-drafts/update",
  reqType: UpdateTalentDraftRequest,
  resType: UpdateTalentDraftResponse,
  isAuthRequired: true,
});
