import { z } from "zod";
import { isSchema } from "../utils/isSchema";
import { Currency } from "./Currency";

export const Money = z.object({
  amount: z.number(),
  currency: Currency,
});

export type Money = z.infer<typeof Money>;

export function isMoney(obj: unknown): obj is Money {
  return isSchema(obj, Money, "Money");
}
