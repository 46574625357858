import { z } from "zod";
import { isSchema } from "../utils/isSchema";
import { ModelMessage } from "./ModelMessage";

export const ModelTrainingExample = z.object({
  messages: z.array(ModelMessage),
});

export type ModelTrainingExample = z.infer<typeof ModelTrainingExample>;

export function isModelTrainingExample(
  obj: unknown
): obj is ModelTrainingExample {
  return isSchema(obj, ModelTrainingExample, "ModelTrainingExample");
}
