import { z } from "zod";
import { getFirebaseFunctionDefinition } from "../utils/getFirebaseFunctionDefinition";

const CancelSubscriptionRequest = z.object({
  subscriptionId: z.string(),
});

const CancelSubscriptionResponse = z.object({});

export type CancelSubscriptionRequest = z.infer<
  typeof CancelSubscriptionRequest
>;

export const CancelSubscriptionDefinition = getFirebaseFunctionDefinition({
  path: "/subscriptions/cancel",
  reqType: CancelSubscriptionRequest,
  resType: CancelSubscriptionResponse,
  isAuthRequired: true,
});
