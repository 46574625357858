import { z } from "zod";
import { isSchema } from "../utils/isSchema";
import { UserRole } from "./UserRole";

export const UserClaims = z.object({
  role: UserRole,
});

export type UserClaims = z.infer<typeof UserClaims>;

export function isUserClaims(obj: unknown): obj is UserClaims {
  return isSchema(obj, UserClaims, "UserClaims");
}
