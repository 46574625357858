import { z } from "zod";
import { isSchema } from "../utils/isSchema";

export const FILE_TYPES = ["IMAGE", "AUDIO"] as const;

export const FileType = z.enum(FILE_TYPES);

export type FileType = z.infer<typeof FileType>;

export function isFileType(obj: unknown): obj is FileType {
  return isSchema(obj, FileType, "FileType");
}
