import { z } from "zod";
import { getFirebaseFunctionDefinition } from "../utils/getFirebaseFunctionDefinition";

const AdminCreateGlobalBroadcastRequest = z.object({
  talentId: z.string().optional(),
  pushNotificationHeadline: z.string().optional(),
  pushNotificationMessage: z.string().optional(),
  chatMessageText: z.string().nonempty(),
  scheduledAt: z.string().datetime(),
});

const AdminCreateGlobalBroadcastResponse = z.object({
  broadcastId: z.string(),
});

export type AdminCreateGlobalBroadcastRequest = z.infer<
  typeof AdminCreateGlobalBroadcastRequest
>;

export const AdminCreateGlobalBroadcastDefinition =
  getFirebaseFunctionDefinition({
    path: "/admin/broadcast/create",
    reqType: AdminCreateGlobalBroadcastRequest,
    resType: AdminCreateGlobalBroadcastResponse,
    isAuthRequired: true,
  });
