import { z } from "zod";
import { PayoutStatus } from "../types/PayoutStatus";
import { getFirebaseFunctionDefinition } from "../utils/getFirebaseFunctionDefinition";

const UpdatePayoutRequest = z.object({
  id: z.string(),
  status: PayoutStatus,
  rejectionReason: z.string(),
});

const UpdatePayoutResponse = z.object({});

export type UpdatePayoutRequest = z.infer<typeof UpdatePayoutRequest>;

export const UpdatePayoutDefinition = getFirebaseFunctionDefinition({
  path: "/payouts/update",
  reqType: UpdatePayoutRequest,
  resType: UpdatePayoutResponse,
  isAuthRequired: true,
});
