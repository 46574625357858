import { z } from "zod";
import { isSchema } from "../utils/isSchema";
import { BroadcastStatus } from "./BroadcastStatus";
import { Timestamp } from "./Timestamp";

export const Broadcast = z.object({
  id: z.string(),
  message: z.string(),
  talentId: z.string().optional(),
  pushNotificationHeadline: z.string().optional(),
  pushNotificationMessage: z.string().optional(),
  scheduledMessageCount: z.number().optional(),
  scheduledMessageCountByTalentIdRecord: z
    .record(z.string(), z.number())
    .optional(),
  sentMessageCount: z.number().optional(),
  sentMessageCountByTalentIdRecord: z.record(z.string(), z.number()).optional(),
  status: BroadcastStatus,
  scheduledTaskId: z.string(),
  scheduledAt: Timestamp,
  startedPreparingAt: Timestamp.optional(),
  startedSendingMessagesAt: Timestamp.optional(),
  completedAt: Timestamp.optional(),
  deletedAt: Timestamp.optional(),
  updatedAt: Timestamp,
  createdAt: Timestamp,
});

export type Broadcast = z.infer<typeof Broadcast>;

export function isBroadcast(obj: unknown): obj is Broadcast {
  return isSchema(obj, Broadcast, "Broadcast");
}
