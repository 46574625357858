import { z } from "zod";
import { isSchema } from "../utils/isSchema";

export const EXPENSE_ACTIONS = [
  "SEND_MESSAGE",
  "UNLOCK_IMAGE",
  "UNLOCK_AUDIO",
] as const;

export const ExpenseAction = z.enum(EXPENSE_ACTIONS);

export type ExpenseAction = z.infer<typeof ExpenseAction>;

export function isExpenseAction(obj: unknown): obj is ExpenseAction {
  return isSchema(obj, ExpenseAction, "ExpenseAction");
}
