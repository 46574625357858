import { z } from "zod";
import { isSchema } from "../utils/isSchema";
import { Money } from "./Money";
import { ProductBillingPeriodType } from "./ProductBillingPeriodType";
import { ProductPriceType } from "./ProductPriceType";
import { Timestamp } from "./Timestamp";

export const Product = z
  .object({
    id: z.string(),
    name: z.string(),
    isAdmin: z.boolean().optional(),
    isActive: z.boolean().optional(),
    createdAt: Timestamp,
  })
  .and(
    z.union([
      z.object({
        priceType: z.literal("ONE_TIME" satisfies ProductPriceType),
        stripeProductId: z.string(),
        stripePriceId: z.string(),
        credits: z.number(),
        price: Money,
        discount: z.string().optional(),
      }),
      z.object({
        priceType: z.literal("SUBSCRIPTION" satisfies ProductPriceType),
        stripeProductId: z.string(),
        stripePriceId: z.string(),
        credits: z.number().optional(),
        price: Money,
        discount: z.string().optional(),
        billingPeriod: z.object({
          type: ProductBillingPeriodType,
          value: z.number(),
        }),
      }),
      z.object({
        priceType: z.literal("APPLE_ONE_TIME" satisfies ProductPriceType),
        appleProductId: z.string(),
        credits: z.number(),
      }),
      z.object({
        priceType: z.literal("GOOGLE_ONE_TIME" satisfies ProductPriceType),
        googleProductId: z.string(),
        credits: z.number(),
      }),
    ])
  );

export type Product = z.infer<typeof Product>;

export function isProduct(obj: unknown): obj is Product {
  return isSchema(obj, Product, "Product");
}
