import { z } from "zod";
import { Dimensions } from "../types/Dimensions";
import { FileOwnerType } from "../types/FileOwnerType";
import { FileType } from "../types/FileType";
import { getFirebaseFunctionDefinition } from "../utils/getFirebaseFunctionDefinition";

const MAX_IMAGE_SIZE = 5_000_000; // 5MB
const ACCEPTED_IMAGE_TYPES = [
  "image/jpeg",
  "image/jpg",
  "image/png",
  "image/webp",
];
const ACCEPTED_AUDIO_TYPES = ["audio/mp3", "audio/mpeg"];

const UploadFileRequest = z.object({
  ownerId: z.string().nonempty(),
  ownerType: FileOwnerType,
  file: z
    .object({
      name: z.string(),
      size: z.number(),
      type: z.string(),
      content: z.string(), // base64 encoded
    })
    .refine((file) => {
      if (ACCEPTED_IMAGE_TYPES.includes(file.type)) {
        return file.size <= MAX_IMAGE_SIZE;
      }

      if (ACCEPTED_AUDIO_TYPES.includes(file.type)) {
        return true;
      }
    }, "Invalid format or file size."),
});

const UploadFileResponse = z
  .object({
    id: z.string(),
    ownerType: FileOwnerType,
    ownerId: z.string(),
    filePath: z.string(),
  })
  .and(
    z.union([
      z.object({
        type: z.literal("IMAGE" satisfies FileType),
        lockedFilePath: z.string(),
        dimensions: Dimensions,
      }),
      z.object({
        type: z.literal("AUDIO" satisfies FileType),
      }),
    ])
  );

export type UploadFileRequest = z.infer<typeof UploadFileRequest>;
export type UploadFileResponse = z.infer<typeof UploadFileResponse>;

export const UploadFileDefinition = getFirebaseFunctionDefinition({
  path: "/file/upload",
  reqType: UploadFileRequest,
  resType: UploadFileResponse,
  isAuthRequired: true,
});
