import { z } from "zod";
import { isSchema } from "../utils/isSchema";

export const TALENT_PERSONALITY_TRAITS = [
  "ATTITUDE",
  "CARING",
  "CASUAL",
  "FUNNY",
  "GENZ",
  "MYSTERIOUS",
  "NAUGHTY",
  "OLD_SOUL",
  "SWEET",
] as const;

export const TalentPersonalityTrait = z.enum(TALENT_PERSONALITY_TRAITS);

export type TalentPersonalityTrait = z.infer<typeof TalentPersonalityTrait>;

export function isTalentPersonalityTrait(
  obj: unknown
): obj is TalentPersonalityTrait {
  return isSchema(obj, TalentPersonalityTrait, "TalentPersonalityTrait");
}
