import { z } from "zod";
import { isSchema } from "../utils/isSchema";

export const SUBSCRIPTION_STATUSES = ["ACTIVE", "PAUSED", "ENDED"] as const;

export const SubscriptionStatus = z.enum(SUBSCRIPTION_STATUSES);

export type SubscriptionStatus = z.infer<typeof SubscriptionStatus>;

export function isSubscriptionStatus(obj: unknown): obj is SubscriptionStatus {
  return isSchema(obj, SubscriptionStatus, "SubscriptionStatus");
}
