import { z } from "zod";
import { isSchema } from "../utils/isSchema";
import { LlmType } from "./LlmType";
import { Timestamp } from "./Timestamp";

export const ChatConfig = z.object({
  id: z.string(),
  sentStaticAttachmentIds: z.array(z.string()),
  overwrittenLlmType: LlmType.optional(),
  overwrittenLlmFineTunedModelId: z.string().optional(),
  createdAt: Timestamp,
});

export type ChatConfig = z.infer<typeof ChatConfig>;

export function isChatConfig(obj: unknown): obj is ChatConfig {
  return isSchema(obj, ChatConfig, "ChatConfig");
}
