import { z } from "zod";
import { Model } from "../types/Model";
import { ModelBase } from "../types/ModelBase";
import { ModelVersion } from "../types/ModelVersion";
import { getFirebaseFunctionDefinition } from "../utils/getFirebaseFunctionDefinition";

const UpdateModelRequest = z.object({
  id: z.string(),
  name: z.string().optional(),
  base: ModelBase.optional(),
  trainingExamples: Model.shape.trainingExamples.optional(),
  defaultVersionId: z.string().optional(), // TODO: remove when migrated to defaultVersion
  defaultFineTunedModelId: z.string().optional(), // TODO: remove when migrated to defaultVersion
  defaultVersion: ModelVersion.optional(),
});

const UpdateModelResponse = z.object({
  modelId: z.string(),
});

export type UpdateModelRequest = z.infer<typeof UpdateModelRequest>;

export const UpdateModelDefinition = getFirebaseFunctionDefinition({
  path: "/models/update",
  reqType: UpdateModelRequest,
  resType: UpdateModelResponse,
  isAuthRequired: true,
});
