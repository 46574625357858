import { z } from "zod";
import { isSchema } from "../utils/isSchema";
import { EarningCategory } from "./EarningCategory";
import { ExpenseAction } from "./ExpenseAction";
import { Money } from "./Money";
import { Timestamp } from "./Timestamp";

export const Earning = z
  .object({
    id: z.string(),
    talentId: z.string(),
    revenueShare: z.number(),
    gross: Money,
    net: Money,
    createdAt: Timestamp,
  })
  .and(
    z.union([
      z.object({
        category: z.literal("CREDITS" satisfies EarningCategory),
        action: ExpenseAction,
        creditsForAction: z.number(),
        pricePerCredit: Money,
        /**
         * The number of PAYED credits.
         */
        credits: z.number(),
        userId: z.string(),
      }),
      z.object({
        category: z.literal("REFERRAL" satisfies EarningCategory),
      }),
      z.object({
        category: z.literal("SUBSCRIPTION" satisfies EarningCategory),
        subscriptionId: z.string(),
        userId: z.string(),
      }),
    ])
  );

export type Earning = z.infer<typeof Earning>;

export function isEarning(obj: unknown): obj is Earning {
  return isSchema(obj, Earning, "Earning");
}
