import { z } from "zod";
import { isSchema } from "../utils/isSchema";

export const FILE_OWNER_TYPES = ["TALENT", "PUBLIC"] as const;

export const FileOwnerType = z.enum(FILE_OWNER_TYPES);

export type FileOwnerType = z.infer<typeof FileOwnerType>;

export function isFileOwnerType(obj: unknown): obj is FileOwnerType {
  return isSchema(obj, FileOwnerType, "FileOwnerType");
}
