import { z } from "zod";
import { isSchema } from "../utils/isSchema";
import { AttachmentType } from "./AttachmentType";
import { Dimensions } from "./Dimensions";

export const Attachment = z
  .object({
    staticAttachmentId: z.string().optional(),
    isUnlocked: z.boolean().optional(),
  })
  .and(
    z.union([
      z.object({
        type: z.literal("IMAGE" satisfies AttachmentType),
        filePath: z.string(),
        dimensions: Dimensions.optional(),
      }),
      z.object({
        type: z.literal("AUDIO" satisfies AttachmentType),
        filePath: z.string().optional(),
      }),
    ])
  );

export type Attachment = z.infer<typeof Attachment>;

export function isAttachment(obj: unknown): obj is Attachment {
  return isSchema(obj, Attachment, "Attachment");
}
