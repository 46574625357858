import { z } from "zod";
import { isSchema } from "../utils/isSchema";
import { Timestamp } from "./Timestamp";

export const Invitation = z.object({
  id: z.string(),
  email: z.string(),
  talentId: z.string(),
  createdAt: Timestamp,
});

export type Invitation = z.infer<typeof Invitation>;

export function isInvitation(obj: unknown): obj is Invitation {
  return isSchema(obj, Invitation, "Invitation");
}
