import { z } from "zod";
import { isSchema } from "../utils/isSchema";

export const SYSTEM_MESSAGE_PLACEHOLDERS = [
  "date",
  "time",
  "faq_question",
  "faq_answer",
  "talent_name",
  "talent_chat_examples",
  "talent_chat_prompt",
  "talent_facts",
  "talent_purpose",
  "talent_personality_traits",
  "talent_interests",
  "talent_gender",
  "talent_age",
] as const;

export const SystemMessagePlaceholder = z.enum(SYSTEM_MESSAGE_PLACEHOLDERS);

export type SystemMessagePlaceholder = z.infer<typeof SystemMessagePlaceholder>;

export function isSystemMessagePlaceholder(
  obj: unknown
): obj is SystemMessagePlaceholder {
  return isSchema(obj, SystemMessagePlaceholder, "SystemMessagePlaceholder");
}
