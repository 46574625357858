import { z } from "zod";
import { isSchema } from "../utils/isSchema";

export const REACTIONS = ["THUMBS_UP", "THUMBS_DOWN"] as const;

export const Reaction = z.enum(REACTIONS);

export type Reaction = z.infer<typeof Reaction>;

export function isReaction(obj: unknown): obj is Reaction {
  return isSchema(obj, Reaction, "Reaction");
}
