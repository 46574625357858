import { z } from "zod";
import { isSchema } from "../utils/isSchema";

export const PAYOUT_STATUSES = [
  "PENDING",
  "EXECUTED",
  "REJECTED",
  "FAILED",
] as const;

export const PayoutStatus = z.enum(PAYOUT_STATUSES);

export type PayoutStatus = z.infer<typeof PayoutStatus>;

export function isPayoutStatus(obj: unknown): obj is PayoutStatus {
  return isSchema(obj, PayoutStatus, "PayoutStatus");
}
