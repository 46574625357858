import { z } from "zod";
import { isSchema } from "../utils/isSchema";
import { Attachment } from "./Attachment";
import { Reaction } from "./Reaction";
import { Timestamp } from "./Timestamp";

export const ChatMessage = z.object({
  id: z.string(),
  chatId: z.string(),
  text: z.string(),
  senderType: z.enum(["USER", "TALENT"]),
  senderId: z.string(),
  localId: z.string().optional(),
  broadcastId: z.string().optional(),
  attachments: z.array(Attachment).optional(),
  isInitial: z.boolean().optional(),
  suggestionId: z.string().optional(),
  reaction: Reaction.optional(),
  reactionFeedback: z.string().optional(),
  createdAt: Timestamp,
});

export type ChatMessage = z.infer<typeof ChatMessage>;

export function isChatMessage(obj: unknown): obj is ChatMessage {
  return isSchema(obj, ChatMessage, "ChatMessage");
}
