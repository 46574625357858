import { z } from "zod";
import { PublicTalentPlacement } from "../types/PublicTalentPlacement";
import { getFirebaseFunctionDefinition } from "../utils/getFirebaseFunctionDefinition";

const AdminUpdatePublicTalentRequest = z.object({
  id: z.string(),
  name: z.string(),
  order: z.number(),
  isVisibleRecord: z.record(PublicTalentPlacement, z.boolean()),
});

const AdminUpdatePublicTalentResponse = z.object({});

export type AdminUpdatePublicTalentRequest = z.infer<
  typeof AdminUpdatePublicTalentRequest
>;

export const AdminUpdatePublicTalentDefinition = getFirebaseFunctionDefinition({
  path: "/admin/public-talents/update",
  reqType: AdminUpdatePublicTalentRequest,
  resType: AdminUpdatePublicTalentResponse,
  isAuthRequired: true,
});
