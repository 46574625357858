import { z } from "zod";
import { isSchema } from "../utils/isSchema";
import { Currency } from "./Currency";
import { Timestamp } from "./Timestamp";

const EarningFrameBase = z.object({
  id: z.string(),
  talentId: z.string(),
  range: z.enum(["DAY", "MONTH", "TOTAL"] as const),
  totalGrossAmount: z.number(),
  totalNetAmount: z.number(),
  sendMessageGrossAmount: z.number().optional(),
  sendMessageNetAmount: z.number().optional(),
  unlockImageGrossAmount: z.number().optional(),
  unlockImageNetAmount: z.number().optional(),
  unlockAudioGrossAmount: z.number().optional(),
  unlockAudioNetAmount: z.number().optional(),
  referralGrossAmount: z.number().optional(),
  referralNetAmount: z.number().optional(),
  subscriptionGrossAmount: z.number().optional(),
  subscriptionNetAmount: z.number().optional(),
  currency: Currency,
  startedAt: Timestamp,
  endedAt: Timestamp,
  updatedAt: Timestamp,
  createdAt: Timestamp,
});

export const ChildEarningFrame = z.object({
  ...EarningFrameBase.shape,
  childEarningFrames: z.array(EarningFrameBase).optional(),
});

export const EarningFrame = z.object({
  ...EarningFrameBase.shape,
  childEarningFrames: z.array(ChildEarningFrame),
});

export type EarningFrame = z.infer<
  typeof EarningFrame | typeof ChildEarningFrame
>;

export function isEarningFrame(obj: unknown): obj is EarningFrame {
  return isSchema(obj, EarningFrame, "EarningFrame");
}
