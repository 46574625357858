import { z } from "zod";
import { isSchema } from "../utils/isSchema";

export const LLM_TYPES = [
  "replicate/llama2-7b",
  "replicate/llama2-13b",
  "replicate/llama2-70b",
  "anyscale/meta-llama/Llama-2-13b-chat-hf",
  "openrouter/gryphe/mythomax-l2-13b",
  "openrouter/gryphe/mythomax-l2-13b-8k",
  "openrouter/nousresearch/nous-hermes-llama2-13b",
  "openrouter/jondurbin/airoboros-l2-70b",
  "haven/mistral-7b",
  "openai/gpt-3.5-turbo",
  "fine-tuned",
] as const;

export const LlmType = z.enum(LLM_TYPES);

export type LlmType = z.infer<typeof LlmType>;

export function isLlmType(obj: unknown): obj is LlmType {
  return isSchema(obj, LlmType, "LlmType");
}
