import { z } from "zod";
import { Reaction } from "../types/Reaction";
import { getFirebaseFunctionDefinition } from "../utils/getFirebaseFunctionDefinition";

const ReactToChatMessageRequest = z.object({
  chatId: z.string(),
  chatMessageId: z.string(),
  reaction: Reaction,
});

const ReactToChatMessageResponse = z.object({});

export type ReactToChatMessageRequest = z.infer<
  typeof ReactToChatMessageRequest
>;
export type ReactToChatMessageResponse = z.infer<
  typeof ReactToChatMessageResponse
>;

export const ReactToChatMessageDefinition = getFirebaseFunctionDefinition({
  path: "/chats/messages/react",
  reqType: ReactToChatMessageRequest,
  resType: ReactToChatMessageResponse,
  isAuthRequired: true,
});
