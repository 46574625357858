import { z } from "zod";
import { isSchema } from "../utils/isSchema";

export const WAITLIST_STATUSES = ["PENDING", "ACCEPTED", "REJECTED"] as const;

export const WaitlistStatus = z.enum(WAITLIST_STATUSES);

export type WaitlistStatus = z.infer<typeof WaitlistStatus>;

export function isWaitlistStatus(obj: unknown): obj is WaitlistStatus {
  return isSchema(obj, WaitlistStatus, "WaitlistStatus");
}
