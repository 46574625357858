import { z } from "zod";
import { SocialPlatform } from "../types/SocialPlatform";
import { getFirebaseFunctionDefinition } from "../utils/getFirebaseFunctionDefinition";

const AddUserToWaitlistRequest = z.object({
  platform: SocialPlatform,
  handle: z.string().nonempty(),
});

const AddUserToWaitlistResponse = z.object({});

export type AddUserToWaitlistRequest = z.infer<typeof AddUserToWaitlistRequest>;

export const AddUserToWaitlistDefinition = getFirebaseFunctionDefinition({
  path: "/waitlist/add-user",
  reqType: AddUserToWaitlistRequest,
  resType: AddUserToWaitlistResponse,
  isAuthRequired: true,
});
