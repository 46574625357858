import { z } from "zod";

/**
 * @interface
 */
export interface Timestamp {
  readonly seconds: number;
  readonly nanoseconds: number;
  isEqual(other: Timestamp): boolean;
  toDate(): Date;
  toMillis: () => number;
}

export class TimestampMock implements Timestamp {
  readonly seconds: number;
  readonly nanoseconds: number;
  constructor(seconds: number, nanoseconds: number);
  constructor(date: Date);
  constructor(arg0: number | Date, arg1?: number) {
    if (typeof arg0 === "number" && typeof arg1 === "number") {
      this.seconds = arg0;
      this.nanoseconds = arg1;
    } else if (arg0 instanceof Date) {
      this.seconds = arg0.getTime() / 1000;
      this.nanoseconds = 0;
    } else {
      throw new Error("Invalid arguments");
    }
  }
  isEqual(other: Timestamp) {
    return (
      other.seconds === this.seconds && other.nanoseconds === this.nanoseconds
    );
  }
  toDate() {
    return new Date(this.toMillis());
  }
  toMillis() {
    return this.seconds * 1000 + this.nanoseconds / 1e6;
  }
}

export const Timestamp = z.custom<Timestamp>((value) => {
  return z
    .object({
      nanoseconds: z.number(),
      seconds: z.number(),
      isEqual: z.function(),
      toDate: z.function(),
      toMillis: z.function(),
    })
    .safeParse(value).success;
});
