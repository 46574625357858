import { z } from "zod";
import { getFirebaseFunctionDefinition } from "../utils/getFirebaseFunctionDefinition";

const ContinueSubscriptionRequest = z.object({
  subscriptionId: z.string(),
});

const ContinueSubscriptionResponse = z.object({});

export type ContinueSubscriptionRequest = z.infer<
  typeof ContinueSubscriptionRequest
>;

export const ContinueSubscriptionDefinition = getFirebaseFunctionDefinition({
  path: "/subscriptions/continue",
  reqType: ContinueSubscriptionRequest,
  resType: ContinueSubscriptionResponse,
  isAuthRequired: true,
});
