import { z } from "zod";
import { ProductPriceType } from "./ProductPriceType";

type TalentId = string;

export type CrmTag =
  | "TALENT_MANAGER"
  | `PURCHASER_WITH_${ProductPriceType}`
  | `CHAT_WITH_${TalentId}`;

export const CrmTag = z.custom<CrmTag>((value) => {
  return typeof value === "string";
});
