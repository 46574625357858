import { z } from "zod";
import { isSchema } from "../utils/isSchema";
import { Voice } from "./Voice";

export const App = z.object({
  id: z.string(),
  isMaintenanceMode: z.boolean(),
  emptyChatMessageText: z.string(),
  defaultChatMessageForAuth: z.string(),
  defaultChatMessageForCheckout: z.string(),
  defaultChatMessageForSubscription: z.string(),
  defaultPromotedTalentId: z.string(),
  termsOfServiceUrl: z.string(),
  privacyPolicyUrl: z.string(),
  voices: z.array(Voice),
  minVersion: z.object({
    messenger: z.string(),
    talent: z.string(),
    venusIos: z.string(),
    venusAndroid: z.string(),
  }),
  reviewVersion: z.object({
    venusIos: z.string(),
    venusAndroid: z.string(),
  }),
  isAvailableInStore: z.object({
    venusIos: z.boolean(),
    venusAndroid: z.boolean(),
  }),
});

export type App = z.infer<typeof App>;

export function isApp(obj: unknown): obj is App {
  return isSchema(obj, App, "App");
}
