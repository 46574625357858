import { z } from "zod";
import { getFirebaseFunctionDefinition } from "../utils/getFirebaseFunctionDefinition";

export const CaptureChatLastUserInteractionRequest = z.object({
  chatId: z.string(),
});

export const CaptureChatLastUserInteractionResponse = z.object({});

export type CaptureChatLastUserInteractionRequest = z.infer<
  typeof CaptureChatLastUserInteractionRequest
>;
export type CaptureChatLastUserInteractionResponse = z.infer<
  typeof CaptureChatLastUserInteractionResponse
>;

export const CaptureChatLastUserInteractionDefinition =
  getFirebaseFunctionDefinition({
    path: "/chats/last-user-interaction/capture",
    reqType: CaptureChatLastUserInteractionRequest,
    resType: CaptureChatLastUserInteractionResponse,
    isAuthRequired: false,
  });
