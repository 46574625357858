import { z } from "zod";
import { StaticAttachment } from "../types/StaticAttachment";
import { getFirebaseFunctionDefinition } from "../utils/getFirebaseFunctionDefinition";

const UpdateTalentConfigAddStaticAttachmentRequest = z.object({
  id: z.string(),
  staticAttachment: StaticAttachment,
});

const UpdateTalentConfigAddStaticAttachmentResponse = z.object({
  staticAttachmentId: z.string(),
});

export type UpdateTalentConfigAddStaticAttachmentRequest = z.infer<
  typeof UpdateTalentConfigAddStaticAttachmentRequest
>;

export const UpdateTalentConfigAddStaticAttachmentDefinition =
  getFirebaseFunctionDefinition({
    path: "/talent-configs/static-attachments/add",
    reqType: UpdateTalentConfigAddStaticAttachmentRequest,
    resType: UpdateTalentConfigAddStaticAttachmentResponse,
    isAuthRequired: true,
  });
