import { z } from "zod";
import { isSchema } from "../utils/isSchema";

export const MODEL_MESSAGE_ROLES = ["SYSTEM", "USER", "ASSISTANT"] as const;

export const ModelMessageRole = z.enum(MODEL_MESSAGE_ROLES);

export type ModelMessageRole = z.infer<typeof ModelMessageRole>;

export function isModelMessageRole(obj: unknown): obj is ModelMessageRole {
  return isSchema(obj, ModelMessageRole, "ModelMessageRole");
}
