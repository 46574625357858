import { z } from "zod";
import { isSchema } from "../utils/isSchema";

export const TALENT_PURPOSES = ["BANTER", "FLIRT", "BULLY", "EDUCATE"] as const;

export const TalentPurpose = z.enum(TALENT_PURPOSES);

export type TalentPurpose = z.infer<typeof TalentPurpose>;

export function isTalentPurpose(obj: unknown): obj is TalentPurpose {
  return isSchema(obj, TalentPurpose, "TalentPurpose");
}
