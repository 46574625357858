import { z } from "zod";
import { getFirebaseFunctionDefinition } from "../utils/getFirebaseFunctionDefinition";

const CreateTalentRequest = z.object({
  name: z.string(),
});

const CreateTalentResponse = z.object({
  talentId: z.string(),
});

export type CreateTalentRequest = z.infer<typeof CreateTalentRequest>;

export const CreateTalentDefinition = getFirebaseFunctionDefinition({
  path: "/talents/create",
  reqType: CreateTalentRequest,
  resType: CreateTalentResponse,
  isAuthRequired: true,
});
