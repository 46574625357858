import { z } from "zod";
import { isSchema } from "../utils/isSchema";

export const TokenUsage = z.object({
  completionTokens: z.number(),
  promptTokens: z.number(),
  totalTokens: z.number(),
});

export type TokenUsage = z.infer<typeof TokenUsage>;

export function isTokenUsage(obj: unknown): obj is TokenUsage {
  return isSchema(obj, TokenUsage, "TokenUsage");
}
