import { z } from "zod";
import { getFirebaseFunctionDefinition } from "../utils/getFirebaseFunctionDefinition";

const AdminCreatePublicTalentRequest = z.object({
  talentId: z.string(),
});

const AdminCreatePublicTalentResponse = z.object({});

export type AdminCreatePublicTalentRequest = z.infer<
  typeof AdminCreatePublicTalentRequest
>;

export const AdminCreatePublicTalentDefinition = getFirebaseFunctionDefinition({
  path: "/admin/public-talents/create",
  reqType: AdminCreatePublicTalentRequest,
  resType: AdminCreatePublicTalentResponse,
  isAuthRequired: true,
});
