import { z } from "zod";
import { getFirebaseFunctionDefinition } from "../utils/getFirebaseFunctionDefinition";

const CreateInvitationRequest = z.object({
  email: z.string(),
  talentId: z.string(),
});

const CreateInvitationResponse = z.object({
  invitationId: z.string().optional(),
});

export type CreateInvitationRequest = z.infer<typeof CreateInvitationRequest>;

export const CreateInvitationDefinition = getFirebaseFunctionDefinition({
  path: "/invitations/create",
  reqType: CreateInvitationRequest,
  resType: CreateInvitationResponse,
  isAuthRequired: true,
});
