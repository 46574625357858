import { z } from "zod";
import { getFirebaseFunctionDefinition } from "../utils/getFirebaseFunctionDefinition";

const CreateChatMessageRequest = z.object({
  chatId: z.string(),
  localId: z.string(),
  text: z.string(),
  timezone: z.string(),
});

const CreateChatMessageResponse = z.object({
  chatMessageId: z.string(),
  totalUserCredits: z.number(),
});

export type CreateChatMessageRequest = z.infer<typeof CreateChatMessageRequest>;

export const CreateChatMessageDefinition = getFirebaseFunctionDefinition({
  path: "/chats/messages/create",
  reqType: CreateChatMessageRequest,
  resType: CreateChatMessageResponse,
  isAuthRequired: true,
});
